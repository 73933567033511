import { useCallback, useEffect, useState, useRef } from "react";
import { Patient, CreatePatient, EditPatient } from "../models/Patient";
import { Button, Col, Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { EditPatientForm } from "./EditPatientForm";
import { useInjection } from "inversify-react";
import { API } from "../services/HTTP";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { relativeTimestamp } from "../services/Utils";
import awsResources from '../aws-resources.json'
import awsConfig from '../aws-config.json'
import { ConfirmModal } from "./ConfirmModal";
import QRCode from "qrcode";

export function PatientTile({ patient, canDelete, blind, includeDetails, reloadFunc }: { patient: Patient, canDelete: boolean, blind: boolean, includeDetails: boolean, reloadFunc: () => Promise<void> }) {

    const [showEditPatientModal, setShowEditPatientModal] = useState(false);
    const [showCreateCarerLinkModal, setShowCreateCarerLinkModal] = useState(false);
    const [showDeletePatientModal, setShowDeletePatientModal] = useState(false);
    const [recentlyCopied, setRecentlyCopied] = useState<boolean>(false);

    const api = useInjection<API>("API");
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    const [tileColor, setTileColor] = useState("success");

    const [coreDevice, setCoreDevice] = useState<string[] | null>(null);

    const [errorMessage, setErrorMessage] = useState<string[]>([]);

    let { domain_id } = useParams();
    const baseUrl = `https://${'web_app_url' in awsConfig ? awsConfig.web_app_url : awsResources.CloudfrontURL}`

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedToday = `${year}-${month}-${day}`;
    const now = new Date();

    const hoursSinceLastUpload = ((now.getTime() - new Date(patient.data!.last_file_uploaded_at!).getTime()) / (60 * 60 * 1000)).toFixed(0);

    useEffect(() => {
        if (patient.associated_devices_ids) {
            const device_ids = patient.associated_devices_ids.filter(associated_device_id => associated_device_id.startsWith("CORE_"));
            const coreMacAddress = device_ids.map(device => device.split("_").pop()!)
            setCoreDevice(coreMacAddress);
        }
    }, [patient.associated_devices_ids])

    useEffect(() => {
        var errs: string[] = [];
        const last_update = new Date(patient.data!.timestamp!);
        const last_alert = new Date(patient.last_alert!);
        const last_acknowledgement = new Date(patient.last_acknowledgement!)

        if (patient.data!.core_connection_status === false || !patient.data!.core_connection_status) {
            errs.push("CORE device is not connected");
        }

        if (patient.data!.core_battery_level! < 20) {
            errs.push("CORE battery is low")
        }

        if (patient.data!.phone_battery_level! < 20) {
            errs.push("Phone battery is low")
        }

        if (patient.data!.core_measurement_quality! === "INVALID") {
            errs.push("CORE measurement is invalid")
        }

        if (now.getTime() - last_update.getTime() > 5 * 60 * 1000) {
            errs.push("App has not sent any data in the last five minutes")
        }

        if (last_alert && now.getTime() - last_alert.getTime() < 72 * 60 * 60 * 1000) {
            errs.push("A fever event was registered in the last 72h")
        }

        if (!patient.data!.last_file_uploaded_at) {
            errs.push("No files have ever been uploaded")
        } else if (+hoursSinceLastUpload > 24) {
            errs.push("No files have been uploaded in the past (x)h")
        }

        if(patient.last_acknowledgement) {
            let last_acknowledgement = new Date(patient.last_acknowledgement) 
            let minutes = 30;  
            if (patient.long_snooze) {
                minutes = 60 * 72; // 72 hours.
            }

            // Last alert was within five minutes, we bail
            if((Date.now() - last_acknowledgement.getTime()) <= (minutes * 60 * 1000)) {
                errs.push("SMS-Alarm is currently on snooze.")
            }
        }

        if (!patient.alert_enabled) {
            // if there is no core_temperature => monitoring is OFF => tile is grey
            setTileColor("secondary");
        }
        else if (patient.data!.core_temperature! >= patient.alert_temperature && patient.data!.core_measurement_quality! !== "INVALID") {
            // if core temp > alert temp => tile is red
            setTileColor("danger");
        }
        else if (errs.length > 0) {
            // if there are issues => tile is orange
            setTileColor("warning");
        }
        else {
            setTileColor("success");
        }

        if ((patient.associated_devices_ids ?? []).filter(d => d.startsWith("LEAP")).length == 0) {
            errs.push("No LEAP device is associated to this patient.")
        }

        if ((patient.associated_devices_ids ?? []).filter(d => d.startsWith("CORE")).length == 0) {
            errs.push("No CORE device is associated to this patient.")
        }

        setErrorMessage(errs)
    }, [patient]);

    const onEditPatient = (() => setShowEditPatientModal(true))

    const editPatient = useCallback(async (editedPatient: EditPatient) => {

        await api.put(`domains/${domain_id}/patients/${patient.id}`, editedPatient);
        reloadFunc();

        setShowEditPatientModal(false);

    }, [api]);

    const onCreateCarerLink = (() => setShowCreateCarerLinkModal(true))

    const CarerLinkModal = () => {

        const url = `${baseUrl}/p/carer/${domain_id}/patients/${patient.id}?lng=${patient.language ?? 'en'}&token=${patient.carer_token}&date=${formattedToday}`;

        const carerLink = useRef<HTMLInputElement>(null);

        const copyLink = () => {
            if (carerLink.current) {
                carerLink.current.select();
                document.execCommand('copy');
            }
        }
        const [qrCode, setQrCode] = useState<string>("");

        useEffect(() => {
            QRCode.toDataURL(url, { type: "image/png" })
                .then(img => {
                    setQrCode(img);
                })
        });

        return <>
            <InputGroup className="mb-3">
                <Col>
                    <Row>
                        <Col className="flex-grow-1">
                            {/* <input type="text" name={`carer_link_${patient.id}`} id={`carer_link_${patient.id}`} value={url} readOnly/> */}
                            <Form.Control
                                value={url}
                                readOnly
                                ref={carerLink}
                            />
                        </Col>
                        <Col xs="auto">
                            <Button
                                variant="outline-secondary"
                                id="button-addon2"
                                onClick={(e) => { e.preventDefault(); setRecentlyCopied(true); copyLink(); }}>
                                {recentlyCopied ? <><i className="bi bi-clipboard-check"></i> &nbsp; {t("Link Copied")} </> : <><i className="bi bi-clipboard"></i> &nbsp; {t("Copy Link")} </>}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <img src={qrCode} style={{height: "256px", width: "256px"}}/>
                    </Row>
                </Col>
            </InputGroup>
        </>
    };

    useEffect(() => {
        if (recentlyCopied) {
            const timer = setTimeout(() => {
                setRecentlyCopied(false);
            }, 3000); // 3 seconds

            return () => clearTimeout(timer);
        }
    }, [recentlyCopied])

    const onDeletePatient = (() => setShowDeletePatientModal(true));

    const archivePatient = useCallback(async () => {
        await api.put(`domains/${domain_id}/patients/${patient.id}/archive`);
        if (includeDetails) {
            window.location.href = `/d/${domain_id}/patients`;
        } else {
            reloadFunc();
        }
    }, [domain_id, patient, reloadFunc]);

    return <div className={`card text-white bg-${tileColor} mb-3`}>
        <div className="card-header d-flex justify-content-between" style={{ fontSize: '20px' }} >
            <div>{patient.id}</div>
            <div>
                <Dropdown>
                    <Dropdown.Toggle variant="default" id="dropdown-basic">
                        <i className="bi bi-three-dots"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {/* Option to display detailed data does not appear when the user is looking at the detailed view already */}
                        {!includeDetails && (
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); navigate(`/d/${domain_id}/patients/${patient.id}/details?date=${formattedToday}`) }} href={`/d/${domain_id}/patients/${patient.id}/details?date=${formattedToday}`}><i className="bi bi-eye-fill"></i> {t("View Data")}</Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={(e) => { e.preventDefault(); onCreateCarerLink() }}><i className="bi bi-share-fill"></i> {t("Carer Link")}</Dropdown.Item>
                        {canDelete && (
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); onEditPatient() }}><i className="bi bi-pencil-fill"></i> {t("Edit")}</Dropdown.Item>
                        )}
                        {canDelete && (
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); onDeletePatient() }} className="text-danger"><i className="bi bi-archive-fill"></i> {t("Archive")}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
        <div className="card-body">
            <h6 className="card-text">{t("Alerts")}:
                {patient.alert_enabled ? (
                    " ON"
                ) : (
                    " OFF"
                )}
            </h6>
            <h6 className="card-text">{t("CORE Temp")}: {(patient.data!.core_measurement_quality! === "INVALID" || patient.data!.core_temperature == undefined ? t('Unknown') : (patient.data!.core_temperature.toFixed(1) + '°'))}</h6>
            {/* Alert temperature does not show if user is in blinded side of experiment */}
            {!blind && <h6 className="card-text">{t("Alert Temp")}: {patient.alert_temperature}&deg;</h6>}
            <h6 className="card-text">{t("Measurement Quality")} <i className="bi bi-info-circle" title={t("signal quality helper text")} style={{ cursor: "help" }}></i> : {patient.data!.core_measurement_quality ?? t('Unknown')}</h6>
            <h6 className="card-text">{t("CORE Battery")}: {patient.data!.core_battery_level ? `${patient.data!.core_battery_level}%` : t('Unknown')}</h6>
            <h6 className="card-text">{t("Phone Battery")}: {patient.data!.phone_battery_level ? `${patient.data!.phone_battery_level}%` : t('Unknown')}</h6>
            <h6 className="card-text">{t("Phone Last Update")} <i className="bi bi-info-circle" title={t("last phone update helper text")} style={{ cursor: "help" }}></i> : {patient.data!.timestamp ? relativeTimestamp(patient.data!.timestamp, t) : t('Never')}</h6>
            {includeDetails && <h6 className="card-text">{t("Created at")}: {patient.created_at ? relativeTimestamp(patient.created_at, t) : t('Unknown')}</h6>}
            {includeDetails && <h6 className="card-text">{t("Last Update")} <i className="bi bi-info-circle" title={t("last update helper text")} style={{ cursor: "help" }}></i> : {patient.updated_at ? relativeTimestamp(patient.updated_at, t) : t('Never')}</h6>}
            {includeDetails && <h6 className="card-text">{t("Last File Upload")}: {patient.data!.last_file_uploaded_at ? relativeTimestamp(patient.data!.last_file_uploaded_at, t) : t('Never')}</h6>}
            {includeDetails && <h6 className="card-text">{t("Core device")}: {(coreDevice && coreDevice.length > 0) ? coreDevice.join(', ') : t("No Core device attached")}</h6>}
            {includeDetails && <h6 className="card-text">{t("Language")}: {t(patient.language ?? 'Unknown')}</h6>}
            <p className="card-text"></p>
            {errorMessage && errorMessage.length > 0 && (
                <div style={{ paddingTop: '10px' }}>
                    <ul className="list-group list-group-flush">
                        {errorMessage.map((error, index) => (
                            <li className="list-group-item text-danger" key={index}>
                                {t(`${error}`, { hours: hoursSinceLastUpload })}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className={`alert alert-${tileColor} mt-3`} role="alert">
                {
                    tileColor === "secondary" ? (<><i className="bi bi-power"></i> {t("Monitoring is off")}</>) :
                        tileColor === "warning" ? (<><i className="bi bi-triangle-half"></i> {t("Issues detected")}</>) :
                            tileColor === "danger" ? (<><i className="bi bi-exclamation-triangle-fill"></i> {t("Potential fever detected")}</>) :
                                tileColor === "success" ? (<><i className="bi bi-check-circle"></i> {t("No issues detected")}</>) :
                                    t("Unknown Status")
                }
            </div>
        </div>

        <Modal show={showEditPatientModal} onHide={() => setShowEditPatientModal(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t("Edit Patient")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditPatientForm editPatient={editPatient} pat={patient} />
            </Modal.Body>
        </Modal>

        <Modal dialogClassName="modal-lg" show={showCreateCarerLinkModal} onHide={() => setShowCreateCarerLinkModal(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t("Carer Link")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CarerLinkModal></CarerLinkModal>
            </Modal.Body>
        </Modal>

        <ConfirmModal
            showModal={showDeletePatientModal}
            setShowModal={setShowDeletePatientModal}
            title="Sure Archive"
            buttonText="Archive"
            activeButtonText="Archiving"
            action={archivePatient}
        ></ConfirmModal>

    </div>
}