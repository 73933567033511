import { useInjection } from "inversify-react";
import { useState, useCallback, useEffect } from "react";
import { API } from "../services/HTTP";
import { UserService } from "../services/User";
import { PublicAPI } from "../services/HTTP";
import { Patient } from "../models/Patient";
import { PatientTile } from "../components/PatientTile";
import { GraphDisplay } from "../components/GraphDisplay";
import { useParams } from "react-router-dom";
import { Spinner, Button, Modal, ProgressBar, Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useQuery, toExcelDate } from "../services/Utils";
import { useTranslation } from "react-i18next";
import { PickDate } from "../components/PickDate";
import { CofemoTimestreamResponse, GenericTimestreamResponse } from "../models/TimestreamResponse";
import { Event } from "../models/Event";
import { ExportData } from "../services/ExportData";
import { ListFiles } from "../components/ListFiles";
import { File } from "../models/File";
import { DomainRepository } from "../services/DomainRepository";
import { Domain } from "../models/Domain";
import _ from "lodash";
import { FetchTokenizedTimestreamData } from "../services/FetchTokenizedTimestreamData";

export function CarerPage(props: { isPublic: boolean }) {
    const [t, i18n] = useTranslation();
    const user = useInjection<UserService>(UserService);
    const api = useInjection<API>("API");
    const publicApi = useInjection<PublicAPI>("PublicAPI");
    const domainRepository = useInjection<DomainRepository>(DomainRepository);
    const exportData = useInjection<ExportData>(ExportData);
    const fetchTimestreamData = useInjection<FetchTokenizedTimestreamData>(FetchTokenizedTimestreamData);

    const urlParams = new URLSearchParams(window.location.search);
    const carerToken = urlParams.get('token');
    const { domain_id } = useParams();
    const { patient_id } = useParams();

    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [domain, setDomain] = useState<Domain>();
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [numEventsToShow, setNumEventsToShow] = useState(10);

    const [patient, setPatient] = useState<Patient | null>(null);
    const [selectedDateFormatted, setSelectedDateFormatted] = useState<string | null>(urlParams.get('date'));
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date(selectedDateFormatted!));

    const [tempData, setTempData] = useState<CofemoTimestreamResponse | null>();
    const [events, setEvents] = useState<Event[]>([]);

    useEffect(() => {
        // This effect is only needed, if we don't have a default language yet.
        if (localStorage.getItem("DEFAULT_LANGUAGE") != null)
            return

        const lng = urlParams.get("lng") ?? "en";
        localStorage.setItem("DEFAULT_LANGUAGE", lng);
        i18n.changeLanguage(lng);
    }, [urlParams, i18n]);

    const reload = useCallback(async () => {
        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;
            
            // set start time of the data query to midnight in the user's local timezone
            const startTimestamp = Math.floor(selectedDate!.setHours(0,0,0) / 1000); // convert to unix in seconds
            const endTimestamp = Math.floor(selectedDate!.setHours(23,59,59) / 1000); 
            
            if (props.isPublic) {
                const patientResponse = await publicApi.get<Patient>(`public/domain/${domain_id}/patients/${patient_id}/carer?token=${carerToken}`);
                setPatient(patientResponse.data);

                const url = `public/domain/${domain_id}/patients/${patient_id}/data?token=${carerToken}&start=${startTimestamp}&end=${endTimestamp}`
                const seriesResponse = await fetchTimestreamData.fetchTokenizedCofemoData(url, true)
                setTempData(seriesResponse)
            } else {
                setDomain(await domainRepository.get(domain_id!));

                const patientResponse = await api.get<Patient>(`domains/${domain_id}/patients/${patient_id}`);
                setPatient(patientResponse.data);

                const url = `domains/${domain_id}/patients/${patient_id}/data?start=${startTimestamp}&end=${endTimestamp}`
                const seriesResponse = await fetchTimestreamData.fetchTokenizedCofemoData(url, false)
                setTempData(seriesResponse)

                const events = await api.get<Event[]>(`domains/${domain_id}/patients/${patient_id}/events`);
                setEvents(events.data);
            }

        };

        return f()
            .catch(err => console.error(err))
            .finally(() => setLoading(false));

    }, [domain_id, carerToken, patient_id, selectedDateFormatted]);


    useEffect(() => {
        reload();
        if (!props.isPublic) {
            user.isAdmin().then(admin => setIsAdmin(admin));
        }
    }, [domain_id, selectedDate]);

    useEffect(() => {
        if (selectedDate!.toDateString() === new Date().toDateString()) {
            const interval = setInterval(() => {
                reload();
            }, 60000);

            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }
    }, [selectedDate])

    const handleDateSelect = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDate(date);
        setSelectedDateFormatted(formattedDate);
        setTempData({ timestamps: [], core_temperature: [] , core_measurement_quality: []});
    }

    const handleShowMore = () => {
        setNumEventsToShow(numEventsToShow + 10); 
    };

    return <>
        <div className="header-container">
            <div>
            <h1 className="header-title">
                {patient_id}
            </h1>
            <small className="header-domain">
                {t("Domain")}: {domain_id}
            </small>
            </div>
            <div>
                {!props.isPublic && (
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            setDownloading(true);
                            setDownloadProgress(0);
                            exportData.downloadCofemoData(domain_id!, patient_id!, (p) => setDownloadProgress(p * 100))
                                .catch(error => console.error('Download error:', error))
                                .finally(() => {
                                    setDownloading(false);
                                });
                        }}
                        disabled={downloading}
                        style={{ marginRight: "10px" }}
                        variant="btn btn-outline-secondary">
                        {downloading && (<>
                            <Spinner as="span" animation="border" size="sm" role="status" /> {t("Downloading")} </>
                        ) || (
                                <>
                                    <i className="bi bi-download"></i> {t("Download All Patient Data")} </>
                            )}
                    </Button>
                )}
                <Button disabled={loading} onClick={(e) => { e.preventDefault(); reload() }} variant="outline-secondary">
                    {loading && (
                        <Spinner as="span" animation="border" size="sm" role="status" />
                    ) || (
                            <i className="bi bi-arrow-clockwise"></i>
                        )}
                </Button>
            </div>
        </div>

        <Modal show={downloading} onHide={() => { }}>
            <Modal.Header>
                <Modal.Title>{t("Collecting data...")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProgressBar animated now={downloadProgress} label={`${Math.round(downloadProgress)}%`} />
            </Modal.Body>
        </Modal>

        <div className="row">
            {patient != null && (
                <div className="col-xxl-3 col-xl-3 col-lg-5 col-md-12 col-sm-12" key={patient!.id}>
                    <PatientTile
                        patient={patient!}
                        canDelete={!props.isPublic}
                        blind={props.isPublic}
                        includeDetails={true}
                        reloadFunc={reload}></PatientTile>
                </div>
            )}
            {tempData != null && (
                <div className="col-xxl-9 col-xl-9 col-lg-7 col-md-12 col-sm-12">
                    <PickDate onDateSelect={handleDateSelect} selectedDate={selectedDate} startDate={new Date(patient!.created_at!)} />
                    <div>
                        {loading && tempData == null ? (<>
                            <Spinner animation="border" />
                        </>) : (<> <div>
                            <GraphDisplay tempData={tempData!} selectedDate={selectedDate!}></GraphDisplay>
                        </div>
                        </>)}
                    </div>
                </div>
            )}
        </div>
        {!props.isPublic && (<>
            <Tabs
            defaultActiveKey="events"
            id="patient-tabs"
            className="mb-3"
            fill
            >
            <Tab eventKey="events" title={<span className="custom-tab">{t("Events")}</span>}>
            <div>
                <h6><>
                    {events.slice(0, numEventsToShow).map((event, index) => (
                        <Container>
                            <Row style={{marginBottom: "3px"}}>
                                <Col xs={2}>
                                <b>{new Date(event.created_at!).toLocaleString(t("dateFormat"), { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</b>:
                                </Col>
                                <Col>
                                {event.text}
                                </Col>
                            </Row>
                        </Container>
                    ))}
                </>
                </h6>
                <div className="d-flex justify-content-center">
                    {events.length > numEventsToShow && <Button onClick={handleShowMore} style={{margin: "10px"}} variant="outline-secondary"> <i className="bi bi-chevron-down"></i> {t("Show More Events")}</Button>}
                </div>
            </div>
            </Tab>
            <Tab eventKey="files" title={<span className="custom-tab">{t("Files")}</span>}>
            <div>
                <ListFiles domain={domain} patientId={patient_id} setFilesLoading={(_) => 0}></ListFiles>
            </div>
            </Tab>
            </Tabs>
            
            </>
        )}
    </>
}